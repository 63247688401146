import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    AddCoverageRequestInterface,
    AddPremiumDeterminingFactorRequestInterface,
    AddRegularDriverRequestInterface,
    AllCoveragesRequestInterface,
    AllProductDetailsRequestInterface,
    CancelAddedCoverageRequestInterface,
    CancelAddedPremiumDeterminingFactorRequestInterface,
    CancelAddedProductRequestInterface,
    CancelAddedRegularDriverRequestInterface,
    CoverageRequestInterface,
    EndDateProductRequestInterface,
    EndProductRequestInterface,
    EndRegularDriverRequestInterface,
    ProductDetailsRequestInterface,
    ProductRequestInterface,
    ReviveProductRequestInterface,
    StartDateProductRequestInterface,
} from '../interfaces';
import * as InsurancePolicyActions from '../store/insurance-policy.actions';
import { InsurancePolicyState } from '../store/insurance-policy.state';
import { InsurancePolicyEventService } from './insurance-policy.event.service';
import { InsurancePolicyApiService } from './insurance-policy.api.service';

@Injectable({
    providedIn: 'root',
})
export class InsurancePolicyActionService {
    constructor(
        private store$: Store<InsurancePolicyState>,
        private insurancePolicyEventService: InsurancePolicyEventService,
        private insurancePolicyApiService:InsurancePolicyApiService,
    ) {}

    public policy$(): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.policy());
        return this.insurancePolicyEventService.policy$();
    }

    public product$(request: ProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.product({ request }));
        return this.insurancePolicyEventService.product$();
    }

    public productDetails$(request: ProductDetailsRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.productDetails({ request }));
        return this.insurancePolicyEventService.productDetails$();
    }

    public allProductDetails$(request: AllProductDetailsRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.allProductDetails({ request }));
        return this.insurancePolicyEventService.allProductDetails$();
    }

    public allCoverages$(request: AllCoveragesRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.allCoverages({ request }));
        return this.insurancePolicyEventService.allCoverages$();
    }

    public coverage$(request: CoverageRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.coverage({ request }));
        return this.insurancePolicyEventService.coverage$();
    }

    public reviveProduct$(request: ReviveProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.reviveProduct({ request }));
        return this.insurancePolicyEventService.reviveProduct$();
    }

    public startDateProduct$(request: StartDateProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.startDateProduct({ request }));
        return this.insurancePolicyEventService.startDateProduct$();
    }

    public endProduct$(request: EndProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.endProduct({ request }));
        return this.insurancePolicyEventService.endProduct$();
    }

    public endDateProduct$(request: EndDateProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.endDateProduct({ request }));
        return this.insurancePolicyEventService.endDateProduct$();
    }

    public cancelAddedProduct$(request: CancelAddedProductRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.cancelAddedProduct({ request }));
        return this.insurancePolicyEventService.cancelAddedProduct$();
    }

    public addPremiumDeterminingFactor$(request: AddPremiumDeterminingFactorRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.addPremiumDeterminingFactor({ request }));
        return this.insurancePolicyEventService.addPremiumDeterminingFactor$();
    }

    public cancelAddedPremiumDeterminingFactor$(
        request: CancelAddedPremiumDeterminingFactorRequestInterface
    ): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.cancelAddedPremiumDeterminingFactor({ request }));
        return this.insurancePolicyEventService.cancelAddedPremiumDeterminingFactor$();
    }

    public addRegularDriver$(request: AddRegularDriverRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.addRegularDriver({ request }));
        return this.insurancePolicyEventService.addRegularDriver$();
    }

    public endRegularDriver$(request: EndRegularDriverRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.endRegularDriver({ request }));
        return this.insurancePolicyEventService.endRegularDriver$();
    }

    public cancelAddedRegularDriver$(request: CancelAddedRegularDriverRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.cancelAddedRegularDriver({ request }));
        return this.insurancePolicyEventService.cancelAddedRegularDriver$();
    }

    public addCoverage$(request: AddCoverageRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.addCoverage({ request }));
        return this.insurancePolicyEventService.addCoverage$();
    }

    public cancelAddedCoverage$(request: CancelAddedCoverageRequestInterface): Observable<void> {
        this.store$.dispatch(InsurancePolicyActions.cancelAddedCoverage({ request }));
        return this.insurancePolicyEventService.cancelAddedCoverage$();
    }

    // public download$(request: CancelAddedCoverageRequestInterface): Observable<string> {
    //     return this.insurancePolicyApiService.download(request);
    // }
}
