import { ApiCoverageIdEnum, CoverageIdEnum } from '../../enums';

export function mapCoverageIdResponse(response: ApiCoverageIdEnum): CoverageIdEnum | null {
    if (!response) {
        return null;
    }

    switch (response) {
        case ApiCoverageIdEnum.Liability:
            return CoverageIdEnum.Liability; // 'Aansprakelijkheid',
        case ApiCoverageIdEnum.CarLegalLiability:
            return CoverageIdEnum.CarLegalLiability; // 'WA',
        case ApiCoverageIdEnum.CarLimitedCasco:
            return CoverageIdEnum.CarLimitedCasco; // 'Beperkt Casco',
        case ApiCoverageIdEnum.CarLimitedCascoExtra:
            return CoverageIdEnum.CarLimitedCascoExtra; // 'Beperkt Casco Extra',
        case ApiCoverageIdEnum.CarAllrisk:
            return CoverageIdEnum.CarAllrisk; // 'Allrisk',
        case ApiCoverageIdEnum.CarDamageInsuranceOccupants:
            return CoverageIdEnum.CarDamageInsuranceOccupants; // 'Schadeverzekering Inzittenden',
        case ApiCoverageIdEnum.CarRoadsideAssistanceNetherlands:
            return CoverageIdEnum.CarRoadsideAssistanceNetherlands; // 'Pechhulp Nederland',
        case ApiCoverageIdEnum.CarRoadsideAssistanceEurope:
            return CoverageIdEnum.CarRoadsideAssistanceEurope; // 'Pechhulp Europa',
        case ApiCoverageIdEnum.CarRedressLegalAid:
            return CoverageIdEnum.CarRedressLegalAid; // 'Verhaalsrechtsbijstand',
        case ApiCoverageIdEnum.CarDirectClaimsHandling:
            return CoverageIdEnum.CarDirectClaimsHandling; // Directe schadeafhandeling Auto,
        case ApiCoverageIdEnum.MotorDirectClaimsHandling:
            return CoverageIdEnum.MotorDirectClaimsHandling; // Directe schadeafhandeling Motor,
        case ApiCoverageIdEnum.MopedLegalLiability:
            return CoverageIdEnum.MopedLegalLiability; // 'WA',
        case ApiCoverageIdEnum.MopedLimitedCasco:
            return CoverageIdEnum.MopedLimitedCasco; // 'Beperkt Casco',
        case ApiCoverageIdEnum.MopedAllrisk:
            return CoverageIdEnum.MopedAllrisk; // 'Allrisk',
        case ApiCoverageIdEnum.CaravanAllriskBenelux:
            return CoverageIdEnum.CaravanAllriskBenelux; // 'Allrisk Benelux en Duitsland',
        case ApiCoverageIdEnum.CaravanAllriskEurope:
            return CoverageIdEnum.CaravanAllriskEurope; // 'Allrisk Europa',
        case ApiCoverageIdEnum.CaravanHouseholdEffects:
            return CoverageIdEnum.CaravanHouseholdEffects; // 'Inboedel',
        case ApiCoverageIdEnum.CaravanExtra:
            return CoverageIdEnum.CaravanExtra; // 'Extra\'s',
        case ApiCoverageIdEnum.CaravanHailBenelux:
            return CoverageIdEnum.CaravanHailBenelux; // 'Hagel Benelux en Duitsland',
        case ApiCoverageIdEnum.CaravanHailEurope:
            return CoverageIdEnum.CaravanHailEurope; // 'Hagel Europa',
        case ApiCoverageIdEnum.PetInsurance:
            return CoverageIdEnum.PetInsurance; // 'Huisdierenverzekering',
        case ApiCoverageIdEnum.PetAccident:
            return CoverageIdEnum.PetAccident; // 'Huisdier & Ongeval',
        case ApiCoverageIdEnum.PetCare50Percent:
            return CoverageIdEnum.PetCare50Percent; // 'Huisdier & Zorg 50%',
        case ApiCoverageIdEnum.PetCare100Percent:
            return CoverageIdEnum.PetCare100Percent; // 'Huisdier & Zorg 100%',
        case ApiCoverageIdEnum.PetExtraCoverage:
            return CoverageIdEnum.PetExtraCoverage; // 'Extra dekking',
        case ApiCoverageIdEnum.PetCancerTreatment:
            return CoverageIdEnum.PetCancerTreatment; // 'Behandeling van kanker',
        case ApiCoverageIdEnum.PetTeeth:
            return CoverageIdEnum.PetTeeth; // 'Gebit',
        case ApiCoverageIdEnum.BicycleTheft:
            return CoverageIdEnum.BicycleTheft; // 'Fiets Diefstal',
        case ApiCoverageIdEnum.BicycleAllrisk:
            return CoverageIdEnum.BicycleAllrisk; // 'Fiets Allrisk',
        case ApiCoverageIdEnum.BicycleHomeDeliveryService:
            return CoverageIdEnum.BicycleHomeDeliveryService; // 'Thuisbrengservice bij pech',
        case ApiCoverageIdEnum.MotorLimitedCasco:
            return CoverageIdEnum.MotorLimitedCasco; // 'WA',
        case ApiCoverageIdEnum.MotorInStorage:
            return CoverageIdEnum.MotorInStorage; // 'Motor op Stal',
        case ApiCoverageIdEnum.MotorAllrisk:
            return CoverageIdEnum.MotorAllrisk; // 'Allrisk',
        case ApiCoverageIdEnum.MotorDamageInsuranceOpputants:
            return CoverageIdEnum.MotorDamageInsuranceOpputants; // 'Schadeverzekering Opzittenden',
        case ApiCoverageIdEnum.MotorRedressLegalAid:
            return CoverageIdEnum.MotorRedressLegalAid; // 'Verhaalsrechtsbijstand',
        case ApiCoverageIdEnum.MotorAccidentPeranentInjury:
            return CoverageIdEnum.MotorAccidentPeranentInjury; // 'Ongeval & Blijvend letsel',
        case ApiCoverageIdEnum.MotorAccidentDeath:
            return CoverageIdEnum.MotorAccidentDeath; // 'Ongeval & Overlijden',
        case ApiCoverageIdEnum.TravelEurope:
            return CoverageIdEnum.TravelEurope; // 'Reisverzekering (basisdekking) Europa',
        case ApiCoverageIdEnum.TravelWorld:
            return CoverageIdEnum.TravelWorld; // 'Werelddekking',
        case ApiCoverageIdEnum.TravelVehicleAssistance:
            return CoverageIdEnum.TravelVehicleAssistance; // 'Voertuighulp',
        case ApiCoverageIdEnum.TravelCancellation:
            return CoverageIdEnum.TravelCancellation; // 'Annulering',
        case ApiCoverageIdEnum.TravelMedicalCostEurope:
            return CoverageIdEnum.TravelMedicalCostEurope; // 'Medische kosten Europa',
        case ApiCoverageIdEnum.TravelMedicalCostWorld:
            return CoverageIdEnum.TravelMedicalCostWorld; // 'Medische kosten Wereld',
        case ApiCoverageIdEnum.HomeHouseholdEffectStandard:
            return CoverageIdEnum.HomeHouseholdEffectStandard; // 'Inboedel Standaard',
        case ApiCoverageIdEnum.HomeBuildingStandard:
            return CoverageIdEnum.HomeBuildingStandard; // 'Opstal Standaard',
        case ApiCoverageIdEnum.HomeHouseholdEffectsComplete:
            return CoverageIdEnum.HomeHouseholdEffectsComplete; // 'Inboedel Compleet',
        case ApiCoverageIdEnum.HomeBuildingComplete:
            return CoverageIdEnum.HomeBuildingComplete; // 'Opstal Compleet',
        case ApiCoverageIdEnum.HomeGlass:
            return CoverageIdEnum.HomeGlass; // 'Glas',
        case ApiCoverageIdEnum.HomeMobileElectronics:
            return CoverageIdEnum.HomeMobileElectronics; // 'Mobiele Elektronica',
        case ApiCoverageIdEnum.HomeOwnerTenantInterest:
            return CoverageIdEnum.HomeOwnerTenantInterest; // 'Eigenaars-/ huurderbelang',
        case ApiCoverageIdEnum.HomeOwnerTenantInterestComplete:
            return CoverageIdEnum.HomeOwnerTenantInterestComplete; // 'Eigenaars-/ huurderbelang Compleet',
        case ApiCoverageIdEnum.LegalAssistanceConsumerAndLiving:
            return CoverageIdEnum.LegalAssistanceConsumerAndLiving; // 'Consument & Wonen',
        case ApiCoverageIdEnum.LegalAssistanceWorkAndIncome:
            return CoverageIdEnum.LegalAssistanceWorkAndIncome; // 'Werk & Inkomen',
        case ApiCoverageIdEnum.LegalAssistanceFamilyAndWealth:
            return CoverageIdEnum.LegalAssistanceFamilyAndWealth; // 'Familie & Vermogen',
        case ApiCoverageIdEnum.LegalAssistanceAccidentAndTraffic:
            return CoverageIdEnum.LegalAssistanceAccidentAndTraffic; // 'Ongeval & Verkeer',
    }
}

export function mapCoverageIdRequest(request: CoverageIdEnum): ApiCoverageIdEnum | null {
    if (!request) {
        return null;
    }

    switch (request) {
        case CoverageIdEnum.Liability:
            return ApiCoverageIdEnum.Liability; // 'Aansprakelijkheid',
        case CoverageIdEnum.CarLegalLiability:
            return ApiCoverageIdEnum.CarLegalLiability; // 'WA',
        case CoverageIdEnum.CarLimitedCasco:
            return ApiCoverageIdEnum.CarLimitedCasco; // 'Beperkt Casco',
        case CoverageIdEnum.CarLimitedCascoExtra:
            return ApiCoverageIdEnum.CarLimitedCascoExtra; // 'Beperkt Casco Extra',
        case CoverageIdEnum.CarAllrisk:
            return ApiCoverageIdEnum.CarAllrisk; // 'Allrisk',
        case CoverageIdEnum.CarDamageInsuranceOccupants:
            return ApiCoverageIdEnum.CarDamageInsuranceOccupants; // 'Schadeverzekering Inzittenden',
        case CoverageIdEnum.CarRoadsideAssistanceNetherlands:
            return ApiCoverageIdEnum.CarRoadsideAssistanceNetherlands; // 'Pechhulp Nederland',
        case CoverageIdEnum.CarRoadsideAssistanceEurope:
            return ApiCoverageIdEnum.CarRoadsideAssistanceEurope; // 'Pechhulp Europa',
        case CoverageIdEnum.CarRedressLegalAid:
            return ApiCoverageIdEnum.CarRedressLegalAid; // 'Verhaalsrechtsbijstand',
        case CoverageIdEnum.CarDirectClaimsHandling:
            return ApiCoverageIdEnum.CarDirectClaimsHandling; // 'Directe schadeafhandeling Auto',
        case CoverageIdEnum.MotorDirectClaimsHandling:
            return ApiCoverageIdEnum.MotorDirectClaimsHandling; // 'Directe schadeafhandeling MOTOR',
        case CoverageIdEnum.MopedLegalLiability:
            return ApiCoverageIdEnum.MopedLegalLiability; // 'WA',
        case CoverageIdEnum.MopedLimitedCasco:
            return ApiCoverageIdEnum.MopedLimitedCasco; // 'Beperkt Casco',
        case CoverageIdEnum.MopedAllrisk:
            return ApiCoverageIdEnum.MopedAllrisk; // 'Allrisk',
        case CoverageIdEnum.CaravanAllriskBenelux:
            return ApiCoverageIdEnum.CaravanAllriskBenelux; // 'Allrisk Benelux en Duitsland',
        case CoverageIdEnum.CaravanAllriskEurope:
            return ApiCoverageIdEnum.CaravanAllriskEurope; // 'Allrisk Europa',
        case CoverageIdEnum.CaravanHouseholdEffects:
            return ApiCoverageIdEnum.CaravanHouseholdEffects; // 'Inboedel',
        case CoverageIdEnum.CaravanExtra:
            return ApiCoverageIdEnum.CaravanExtra; // 'Extra\'s',
        case CoverageIdEnum.CaravanHailBenelux:
            return ApiCoverageIdEnum.CaravanHailBenelux; // 'Hagel Benelux en Duitsland',
        case CoverageIdEnum.CaravanHailEurope:
            return ApiCoverageIdEnum.CaravanHailEurope; // 'Hagel Europa',
        case CoverageIdEnum.PetInsurance:
            return ApiCoverageIdEnum.PetInsurance; // 'Huisdier verzekering',
        case CoverageIdEnum.PetAccident:
            return ApiCoverageIdEnum.PetAccident; // 'Huisdier & Ongeval',
        case CoverageIdEnum.PetCare50Percent:
            return ApiCoverageIdEnum.PetCare50Percent; // 'Huisdier & Zorg 50%',
        case CoverageIdEnum.PetCare100Percent:
            return ApiCoverageIdEnum.PetCare100Percent; // 'Huisdier & Zorg 100%',
        case CoverageIdEnum.PetExtraCoverage:
            return ApiCoverageIdEnum.PetExtraCoverage; // 'Extra dekking',
        case CoverageIdEnum.PetCancerTreatment:
            return ApiCoverageIdEnum.PetCancerTreatment; // 'Behandeling van kanker',
        case CoverageIdEnum.PetTeeth:
            return ApiCoverageIdEnum.PetTeeth; // 'Gebit',
        case CoverageIdEnum.BicycleTheft:
            return ApiCoverageIdEnum.BicycleTheft; // 'Fiets Diefstal',
        case CoverageIdEnum.BicycleAllrisk:
            return ApiCoverageIdEnum.BicycleAllrisk; // 'Fiets Allrisk',
        case CoverageIdEnum.BicycleHomeDeliveryService:
            return ApiCoverageIdEnum.BicycleHomeDeliveryService; // 'Thuisbrengservice bij pech',
        case CoverageIdEnum.MotorLimitedCasco:
            return ApiCoverageIdEnum.MotorLimitedCasco; // 'WA',
        case CoverageIdEnum.MotorInStorage:
            return ApiCoverageIdEnum.MotorInStorage; // 'Motor op Stal',
        case CoverageIdEnum.MotorAllrisk:
            return ApiCoverageIdEnum.MotorAllrisk; // 'Allrisk',
        case CoverageIdEnum.MotorDamageInsuranceOpputants:
            return ApiCoverageIdEnum.MotorDamageInsuranceOpputants; // 'Schadeverzekering Opzittenden',
        case CoverageIdEnum.MotorRedressLegalAid:
            return ApiCoverageIdEnum.MotorRedressLegalAid; // 'Verhaalsrechtsbijstand',
        case CoverageIdEnum.MotorAccidentPeranentInjury:
            return ApiCoverageIdEnum.MotorAccidentPeranentInjury; // 'Ongeval & Blijvend letsel',
        case CoverageIdEnum.MotorAccidentDeath:
            return ApiCoverageIdEnum.MotorAccidentDeath; // 'Ongeval & Overlijden',
        case CoverageIdEnum.TravelEurope:
            return ApiCoverageIdEnum.TravelEurope; // 'Reisverzekering (basisdekking) Europa',
        case CoverageIdEnum.TravelWorld:
            return ApiCoverageIdEnum.TravelWorld; // 'Werelddekking',
        case CoverageIdEnum.TravelVehicleAssistance:
            return ApiCoverageIdEnum.TravelVehicleAssistance; // 'Voertuighulp',
        case CoverageIdEnum.TravelCancellation:
            return ApiCoverageIdEnum.TravelCancellation; // 'Annulering',
        case CoverageIdEnum.TravelMedicalCostEurope:
            return ApiCoverageIdEnum.TravelMedicalCostEurope; // 'Medische kosten Europa',
        case CoverageIdEnum.TravelMedicalCostWorld:
            return ApiCoverageIdEnum.TravelMedicalCostWorld; // 'Medische kosten Wereld',
        case CoverageIdEnum.HomeHouseholdEffectStandard:
            return ApiCoverageIdEnum.HomeHouseholdEffectStandard; // 'Inboedel Standaard',
        case CoverageIdEnum.HomeBuildingStandard:
            return ApiCoverageIdEnum.HomeBuildingStandard; // 'Opstal Standaard',
        case CoverageIdEnum.HomeHouseholdEffectsComplete:
            return ApiCoverageIdEnum.HomeHouseholdEffectsComplete; // 'Inboedel Compleet',
        case CoverageIdEnum.HomeBuildingComplete:
            return ApiCoverageIdEnum.HomeBuildingComplete; // 'Opstal Compleet',
        case CoverageIdEnum.HomeGlass:
            return ApiCoverageIdEnum.HomeGlass; // 'Glas',
        case CoverageIdEnum.HomeMobileElectronics:
            return ApiCoverageIdEnum.HomeMobileElectronics; // 'Mobiele Elektronica',
        case CoverageIdEnum.HomeOwnerTenantInterest:
            return ApiCoverageIdEnum.HomeOwnerTenantInterest; // 'Eigenaars-/ huurderbelang',
        case CoverageIdEnum.HomeOwnerTenantInterestComplete:
            return ApiCoverageIdEnum.HomeOwnerTenantInterestComplete; // 'Eigenaars-/ huurderbelang Compleet',
        case CoverageIdEnum.LegalAssistanceConsumerAndLiving:
            return ApiCoverageIdEnum.LegalAssistanceConsumerAndLiving; // 'Consument & Wonen',
        case CoverageIdEnum.LegalAssistanceWorkAndIncome:
            return ApiCoverageIdEnum.LegalAssistanceWorkAndIncome; // 'Werk & Inkomen',
        case CoverageIdEnum.LegalAssistanceFamilyAndWealth:
            return ApiCoverageIdEnum.LegalAssistanceFamilyAndWealth; // 'Familie & Vermogen',
        case CoverageIdEnum.LegalAssistanceAccidentAndTraffic:
            return ApiCoverageIdEnum.LegalAssistanceAccidentAndTraffic; // 'Ongeval & Verkeer',
    }
}
